<template>
   <div class="accordion" id="accordionExample">
      <div class="accordion-item">
         <h2 class="accordion-header" id="headingOne">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
               <i class="fal fa-money-bill-wave font-12"></i> Informações gerais
            </button>
         </h2>
         <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
            <div class="accordion-body">
               <div class="cards-150 cards-sm-200">
                  <div class="card shadow-none" style="grid-column: span 2;">
                     <div class="card-body p-12">
                        <h1 class="font-12 mb-1 limitador-1 text-uppercase">Chave</h1>
                        <p class="font-12 mb-0 text-secondary text-end"><span class="limitador-1">{{ nota.chave == null ? 'Sem chave' : nota.chave }}</span></p>
                     </div>
                  </div>
                  <div class="card shadow-none">
                     <div class="card-body p-12">
                        <h1 class="font-12 mb-1 limitador-1 text-uppercase">Valor total</h1>
                        <p class="font-12 mb-0 text-secondary text-end">
                           <span class="limitador-1">{{ nota.valor == null ? 'Sem valor' : 'R$ ' + parseFloat(nota.valor).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
                        </p>
                     </div>
                  </div>
                  <div class="card shadow-none">
                     <div class="card-body p-12">
                        <h1 class="font-12 mb-1 limitador-1 text-uppercase">Status</h1>
                        <p class="font-12 mb-0 text-secondary text-end"><span class="limitador-1">{{ nota.status == null ? 'Sem status' : nota.status }}</span></p>
                     </div>
                  </div>
                  <div class="card shadow-none">
                     <div class="card-body p-12">
                        <h1 class="font-12 mb-1 limitador-1 text-uppercase">Data emissão</h1>
                        <p class="font-12 mb-0 text-secondary text-end">
                           <span class="limitador-1">{{ nota.dataNota == null ? 'Sem data' : String(nota.dataNota.dayOfMonth).padStart(2, '0') + '/' + String(nota.dataNota.monthValue).padStart(2, '0') + '/' + String(nota.dataNota.year) + ' ' + String(nota.dataNota.hour).padStart(2, '0') + ':' + String(nota.dataNota.minute).padStart(2, '0') }}</span>
                        </p>
                     </div>
                  </div>
                  <div class="card shadow-none">
                     <div class="card-body p-12">
                        <h1 class="font-12 mb-1 limitador-1 text-uppercase">Data recebimento</h1>
                        <p class="font-12 mb-0 text-secondary text-end">
                           <span class="limitador-1">{{ nota.dataRecebimento == null ? 'Sem data' : String(nota.dataRecebimento.dayOfMonth).padStart(2, '0') + '/' + String(nota.dataRecebimento.monthValue).padStart(2, '0') + '/' + String(nota.dataRecebimento.year) + ' ' + String(nota.dataRecebimento.hour).padStart(2, '0') + ':' + String(nota.dataRecebimento.minute).padStart(2, '0') }}</span>
                        </p>
                     </div>
                  </div>
                  <div class="card shadow-none">
                     <div class="card-body p-12">
                        <h1 class="font-12 mb-1 limitador-1 text-uppercase">Data finalizado</h1>
                        <p class="font-12 mb-0 text-secondary text-end">
                           <span class="limitador-1">{{ nota.dataFinalizado == null ? 'Sem data' : String(nota.dataFinalizado.dayOfMonth).padStart(2, '0') + '/' + String(nota.dataFinalizado.monthValue).padStart(2, '0') + '/' + String(nota.dataFinalizado.year) + ' ' + String(nota.dataFinalizado.hour).padStart(2, '0') + ':' + String(nota.dataFinalizado.minute).padStart(2, '0') }}</span>
                        </p>
                     </div>
                  </div>
                  <div class="card shadow-none">
                     <div class="card-body p-12">
                        <h1 class="font-12 mb-1 limitador-1 text-uppercase">Entrada estoque</h1>
                        <p class="font-12 mb-0 text-secondary text-end"><span class="limitador-1">{{ nota.entradaEstoque == null ? 'Sem configuração' : nota.entradaEstoque }}</span></p>
                     </div>
                  </div>
                  <div class="card shadow-none">
                     <div class="card-body p-12">
                        <h1 class="font-12 mb-1 limitador-1 text-uppercase">Valor desconto</h1>
                        <p class="font-12 mb-0 text-secondary text-end">
                           <span class="limitador-1">{{ nota.desconto == null ? 'Sem valor' : 'R$ ' + parseFloat(nota.desconto).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
                        </p>
                     </div>
                  </div>
                  <div class="card shadow-none">
                     <div class="card-body p-12">
                        <h1 class="font-12 mb-1 limitador-1 text-uppercase">Valor PIS</h1>
                        <p class="font-12 mb-0 text-secondary text-end">
                           <span class="limitador-1">{{ nota.pis == null ? 'Sem PIS' : 'R$ ' + parseFloat(nota.pis).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
                        </p>
                     </div>
                  </div>
                  <div class="card shadow-none">
                     <div class="card-body p-12">
                        <h1 class="font-12 mb-1 limitador-1 text-uppercase">Valor COFINS</h1>
                        <p class="font-12 mb-0 text-secondary text-end">
                           <span class="limitador-1">{{ nota.cofins == null ? 'Sem COFINS' : 'R$ ' + parseFloat(nota.cofins).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
                        </p>
                     </div>
                  </div>
                  <div class="card shadow-none">
                     <div class="card-body p-12">
                        <h1 class="font-12 mb-1 limitador-1 text-uppercase">Valor IPI</h1>
                        <p class="font-12 mb-0 text-secondary text-end">
                           <span class="limitador-1">{{ nota.ipi == null ? 'Sem IPI' : 'R$ ' + parseFloat(nota.ipi).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
                        </p>
                     </div>
                  </div>
                  <div class="card shadow-none">
                     <div class="card-body p-12">
                        <h1 class="font-12 mb-1 limitador-1 text-uppercase">BC ICMS</h1>
                        <p class="font-12 mb-0 text-secondary text-end">
                           <span class="limitador-1">{{ nota.baseIcmsTrib == null ? 'Sem BC' : 'R$ ' + parseFloat(nota.baseIcmsTrib).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
                        </p>
                     </div>
                  </div>
                  <div class="card shadow-none">
                     <div class="card-body p-12">
                        <h1 class="font-12 mb-1 limitador-1 text-uppercase">BC ICMS ST</h1>
                        <p class="font-12 mb-0 text-secondary text-end">
                           <span class="limitador-1">{{ nota.baseIcmsSt == null ? 'Sem BC' : 'R$ ' + parseFloat(nota.baseIcmsSt).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
                        </p>
                     </div>
                  </div>
                  <div class="card shadow-none">
                     <div class="card-body p-12">
                        <h1 class="font-12 mb-1 limitador-1 text-uppercase">Valor ICMS</h1>
                        <p class="font-12 mb-0 text-secondary text-end">
                           <span class="limitador-1">{{ nota.valorIcmsTrib == null ? 'Sem valor' : 'R$ ' + parseFloat(nota.valorIcmsTrib).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
                        </p>
                     </div>
                  </div>
                  <div class="card shadow-none">
                     <div class="card-body p-12">
                        <h1 class="font-12 mb-1 limitador-1 text-uppercase">Valor ICMS ST</h1>
                        <p class="font-12 mb-0 text-secondary text-end">
                           <span class="limitador-1">{{ nota.valorIcmsSt == null ? 'Sem valor' : 'R$ ' + parseFloat(nota.valorIcmsSt).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
                        </p>
                     </div>
                  </div>
                  <div class="card shadow-none">
                     <div class="card-body p-12">
                        <h1 class="font-12 mb-1 limitador-1 text-uppercase">Valor frete</h1>
                        <p class="font-12 mb-0 text-secondary text-end">
                           <span class="limitador-1">{{ nota.valorFrete == null ? 'Sem valor' : 'R$ ' + parseFloat(nota.valorFrete).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
                        </p>
                     </div>
                  </div>
                  <div class="card shadow-none">
                     <div class="card-body p-12">
                        <h1 class="font-12 mb-1 limitador-1 text-uppercase">Tipo emissão</h1>
                        <p class="font-12 mb-0 text-secondary text-end"><span class="limitador-1">{{ nota.tipoEmissao == null ? 'Sem tipo emissão' : nota.tipoEmissao }}</span></p>
                     </div>
                  </div>
                  <div class="card shadow-none">
                     <div class="card-body p-12">
                        <h1 class="font-12 mb-1 limitador-1 text-uppercase">Tipo nota</h1>
                        <p class="font-12 mb-0 text-secondary text-end"><span class="limitador-1">{{ nota.tipoNota == null ? 'Sem tipo nota' : nota.tipoNota }}</span></p>
                     </div>
                  </div>
                  <div class="card shadow-none">
                     <div class="card-body p-12">
                        <h1 class="font-12 mb-1 limitador-1 text-uppercase">Tipo operação</h1>
                        <p class="font-12 mb-0 text-secondary text-end"><span class="limitador-1">{{ nota.tipoOperacao == null ? 'Sem tipo operação' : nota.tipoOperacao }}</span></p>
                     </div>
                  </div>
                  <div class="card shadow-none">
                     <div class="card-body p-12">
                        <h1 class="font-12 mb-1 limitador-1 text-uppercase">Tipo consumidor</h1>
                        <p class="font-12 mb-0 text-secondary text-end">
                           <span class="limitador-1">{{ nota.indConsumidor == null ? 'Sem tipo consumidor' : String(nota.indConsumidor).replace('_', ' ') }}</span>
                        </p>
                     </div>
                  </div>
                  <div class="card shadow-none">
                     <div class="card-body p-12">
                        <h1 class="font-12 mb-1 limitador-1 text-uppercase">Tipo finalidade</h1>
                        <p class="font-12 mb-0 text-secondary text-end"><span class="limitador-1">{{ nota.indFinalidade == null ? 'Sem tipo finalidade' : nota.indFinalidade }}</span></p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="accordion-item">
         <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
               <i class="fal fa-truck"></i> Informações emitente
            </button>
         </h2>
         <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
            <div class="accordion-body">
               <div class="cards-150 cards-sm-200">
                  <div class="card shadow-none" style="grid-column: span 2;">
                     <div class="card-body p-12">
                        <h1 class="font-12 mb-1 limitador-1 text-uppercase">Razão social</h1>
                        <p class="font-12 mb-0 text-secondary text-end"><span class="limitador-1">{{ nota.razaoSocialEmitente == null ? 'Sem razão social' : nota.razaoSocialEmitente }}</span></p>
                     </div>
                  </div>
                  <div class="card shadow-none">
                     <div class="card-body p-12">
                        <h1 class="font-12 mb-1 limitador-1 text-uppercase">Nome fantasia</h1>
                        <p class="font-12 mb-0 text-secondary text-end"><span class="limitador-1">{{ nota.nomeEmitente == null ? 'Sem nome' : nota.nomeEmitente }}</span></p>
                     </div>
                  </div>
                  <div class="card shadow-none">
                     <div class="card-body p-12">
                        <h1 class="font-12 mb-1 limitador-1 text-uppercase">CPF/CNPJ</h1>
                        <p class="font-12 mb-0 text-secondary text-end"><span class="limitador-1">{{ nota.cpfCnpjEmitente == null ? 'Sem CPF/CNPJ' : nota.cpfCnpjEmitente }}</span></p>
                     </div>
                  </div>
                  <div class="card shadow-none" style="grid-column: span 2;">
                     <div class="card-body p-12">
                        <h1 class="font-12 mb-1 limitador-1 text-uppercase">Endereço</h1>
                        <p class="font-12 mb-0 text-secondary text-end">
                           <span class="limitador-1">{{ nota.enderecoEmitente == null ? 'Sem endereço' : nota.enderecoEmitente +', '+ nota.numeroEnderecoEmitente +' - '+ nota.setorEmitente +' - '+ nota.xMunicipioEmitente +'/'+ nota.estadoEmitente }}</span>
                        </p>
                     </div>
                  </div>
                  <div class="card shadow-none">
                     <div class="card-body p-12">
                        <h1 class="font-12 mb-1 limitador-1 text-uppercase">Inscrição estadual</h1>
                        <p class="font-12 mb-0 text-secondary text-end"><span class="limitador-1">{{ nota.inscricaoEstadualEmitente == null ? 'Sem IE' : nota.inscricaoEstadualEmitente }}</span></p>
                     </div>
                  </div>
                  <div class="card shadow-none">
                     <div class="card-body p-12">
                        <h1 class="font-12 mb-1 limitador-1 text-uppercase">CEP</h1>
                        <p class="font-12 mb-0 text-secondary text-end"><span class="limitador-1">{{ nota.cepEmitente == null ? 'Sem CEP' : nota.cepEmitente }}</span></p>
                     </div>
                  </div>
                  <div class="card shadow-none">
                     <div class="card-body p-12">
                        <h1 class="font-12 mb-1 limitador-1 text-uppercase">Telefone</h1>
                        <p class="font-12 mb-0 text-secondary text-end"><span class="limitador-1">{{ nota.telefoneEmitente == null ? 'Sem telefone' : nota.telefoneEmitente }}</span></p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="accordion-item">
         <h2 class="accordion-header" id="headingThree">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
               <i class="fal fa-dolly"></i> Informações destinatário
            </button>
         </h2>
         <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
            <div class="accordion-body">
               <div class="cards-150 cards-sm-200">
                  <div class="card shadow-none" style="grid-column: span 2;">
                     <div class="card-body p-12">
                        <h1 class="font-12 mb-1 limitador-1 text-uppercase">Razão social</h1>
                        <p class="font-12 mb-0 text-secondary text-end"><span class="limitador-1">{{ nota.razaoSocialDestinatario == null ? 'Sem razão social' : nota.razaoSocialDestinatario }}</span></p>
                     </div>
                  </div>
                  <div class="card shadow-none">
                     <div class="card-body p-12">
                        <h1 class="font-12 mb-1 limitador-1 text-uppercase">Nome fantasia</h1>
                        <p class="font-12 mb-0 text-secondary text-end"><span class="limitador-1">{{ nota.nomeDestinatario == null ? 'Sem nome' : nota.nomeDestinatario }}</span></p>
                     </div>
                  </div>
                  <div class="card shadow-none">
                     <div class="card-body p-12">
                        <h1 class="font-12 mb-1 limitador-1 text-uppercase">CPF/CNPJ</h1>
                        <p class="font-12 mb-0 text-secondary text-end"><span class="limitador-1">{{ nota.cpfCnpjDestinatario == null ? 'Sem CPF/CNPJ' : nota.cpfCnpjDestinatario }}</span></p>
                     </div>
                  </div>
                  <div class="card shadow-none" style="grid-column: span 2;">
                     <div class="card-body p-12">
                        <h1 class="font-12 mb-1 limitador-1 text-uppercase">Endereço</h1>
                        <p class="font-12 mb-0 text-secondary text-end">
                           <span class="limitador-1">{{ nota.enderecoDestinatario == null ? 'Sem endereço' : nota.enderecoDestinatario +', '+ nota.numeroEnderecoDestinatario +' - '+ nota.setorDestinatario +' - '+ nota.xMunicipioDestinatario +'/'+ nota.estadoDestinatario }}</span>
                        </p>
                     </div>
                  </div>
                  <div class="card shadow-none">
                     <div class="card-body p-12">
                        <h1 class="font-12 mb-1 limitador-1 text-uppercase">Inscrição estadual</h1>
                        <p class="font-12 mb-0 text-secondary text-end"><span class="limitador-1">{{ nota.inscricaoEstadualDestinatario == null ? 'Sem IE' : nota.inscricaoEstadualDestinatario }}</span></p>
                     </div>
                  </div>
                  <div class="card shadow-none">
                     <div class="card-body p-12">
                        <h1 class="font-12 mb-1 limitador-1 text-uppercase">CEP</h1>
                        <p class="font-12 mb-0 text-secondary text-end"><span class="limitador-1">{{ nota.cepDestinatario == null ? 'Sem CEP' : nota.cepDestinatario }}</span></p>
                     </div>
                  </div>
                  <div class="card shadow-none">
                     <div class="card-body p-12">
                        <h1 class="font-12 mb-1 limitador-1 text-uppercase">Telefone</h1>
                        <p class="font-12 mb-0 text-secondary text-end"><span class="limitador-1">{{ nota.telefoneDestinatario == null ? 'Sem telefone' : nota.telefoneDestinatario }}</span></p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="accordion-item">
         <h2 class="accordion-header" id="headingFour">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
               <i class="fal fa-carrot"></i> Produtos
            </button>
         </h2>
         <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
            <div class="accordion-body">
               <div class="row">
                  <produto v-for="(produto, index) in nota.lstItensNotaEletronica" :key="index" :produto="produto" :index="index" :showEdit="false"  />
                  
                  <div class="col-12 my-5 text-center" v-if="nota.lstItensNotaEletronica.length == 0">
                     <i class="fal fa-box-open font-60 text-secondary opacity-50 d-block mb-3"></i>
                     <h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum produto encontrado</h4>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import produto from '@/components/fiscal/notas/Produto.vue'

export default {
   name: 'AccordionNota',
   props: ['nota'],
   components: {
		produto
	}
}

</script>