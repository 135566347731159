<template>
   <div class="col-12 px-0 mb-1" :class="tabInfo != null ? 'main-container' : ''">
      <div class="card mb-0" :class="index % 2 == 1 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="col-lg text-center text-lg-start">
                  <h1 class="font-15 mb-0 limitador-1 text-capitalize">{{ produto.descricaoProduto == null ? 'Sem nome' : produto.descricaoProduto }}</h1>
                  <p class="font-10 mb-0 limitador-1 text-secondary">
                     <span><i class="far fa-hashtag font-9 me-1"></i>{{ produto.id }}</span>
                     <span class="ms-3"><i class="far fa-tag font-9 me-1"></i>{{ produto.infAdProd == null ? '-' : produto.infAdProd }}</span>
                  </p>
                  <div class="row row-cols-2 row-cols-xxl-3 align-items-center mt-1">
                     <div class="px-0 d-none d-xxl-block">
                        <p class="font-12 mb-0 limitador-2">
                           <i class="far fa-barcode color-theme font-10 me-1"></i><strong class="subtitle me-1">Código:</strong>
                           <span class="d-md-block d-xxl-inline">{{ produto.codigoEanProduto == null ? 'Sem código' : produto.codigoEanProduto }}</span>
                        </p>
                     </div>
                     <div class="px-0">
                        <p class="font-12 mb-0 limitador-2">
                           <i class="far fa-money-bill-wave-alt color-theme font-10 me-1"></i><strong class="subtitle me-1">Total:</strong>
                           <span class="d-sm-block d-md-inline d-xxl-inline">
                              <small>R$ </small>{{ produto.total == null ? '0,00' : parseFloat(produto.total).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
                           </span>
                        </p>
                     </div>
                     <div class="px-0 d-flex justify-content-center justify-content-lg-start">
                        <a v-if="produto.vinculado" href="javascript:;" @click="toggleInfo('Preços')" class="badge me-1 px-2" :class="tabInfo == 'Preços' ? 'badge-default bg-theme' : 'badge-default-outline'">
                           <i class="far fa-money-bill font-10"></i><span class="d-none d-sm-inline d-lg-none d-xxl-inline ms-1"> Preços</span>
                        </a>
                        <a href="javascript:;" @click="toggleInfo('Adicional')" class="badge px-2" :class="tabInfo == 'Adicional' ? 'badge-default bg-theme' : 'badge-default-outline'">
                           <i class="far fa-info-circle font-10"></i><span class="d-none d-sm-inline d-lg-none d-xxl-inline ms-1"> Inf. Adicional</span>
                        </a>
                     </div>
                  </div>
               </div>
               <div class="wpx-115 text-center mx-auto ms-lg-0 mt-2 mt-lg-0" v-if="showEdit && dadosUsuario.usuarioPermissoes.includes('Fiscal | Editar vinculos')">
                  <label><i class="far fa-box color-theme font-10 me-1"></i> Quantidade</label>
                  <input type="text" class="form-control input-sm" v-model="produto.qtd" />
               </div>
               <div class="wpx-115 text-center mx-auto mx-lg-0 mt-2 mt-lg-0 font-15" v-else>
                  <span class="d-block lh-1">
                     {{ parseFloat(produto.qtd).toLocaleString('pt-br', {minimumFractionDigits: 0, maximumFractionDigits: 4}) }}
                     <small class="font-11">{{ produto.unidadeProduto == null || String(produto.unidadeProduto).trim().length == 0 ? '-' : produto.unidadeProduto }}</small>
                  </span>
                  <small class="font-12"><i class="far fa-box color-theme font-10 me-1"></i> Quantidade</small>
               </div>
               <div class="wpx-115 text-center mx-auto ms-lg-0 mt-2 mt-lg-0" v-if="showEdit && dadosUsuario.usuarioPermissoes.includes('Fiscal | Editar vinculos')">
                  <label><i class="far fa-dollar-sign color-theme font-10 me-1"></i> Unitário</label>
                  <input type="text" class="form-control input-sm" v-model="produto.unitario" />
               </div>
               <div class="wpx-115 text-center mx-auto mx-lg-0 mt-2 mt-lg-0 font-15" v-else>
                  <span class="d-block lh-1"><small>R$ </small>{{ parseFloat(produto.unitario).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 4}) }}</span>
                  <small class="font-12"><i class="far fa-money-bill color-theme font-10 me-1"></i> Unitário</small>   
               </div>
               <div class="wpx-125 text-center mx-auto ms-lg-0 mt-2 mt-lg-0" v-if="showEdit && entradaEstoque == 'Manifestação nota' && produto.vinculado">
                  <label><i class="far fa-box color-theme font-10 me-1"></i> Estoque</label>
                  <v-select class="w-100 select-sm" :options="produto.estoques" v-model="produto.idEstoque" label="nome" :reduce="e => e.idEstoque" :clearable="false" :searchable="false" placeholder="-">
                     <template #option="{nome, quantidade}">{{ nome }}<small class="d-block"><i class="far fa-box font-9 me-1"></i>x{{ quantidade == null ? 0 : parseFloat(quantidade).toFixed(1) }}</small></template>
                     <template v-slot:no-options>
                        <span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Utilize a tela "Estoque" > "Gerenciamento" para adicionar</small>
                     </template>
                  </v-select>
               </div>
               <div class="wpx-125 wpx-xs-100 text-center font-16 mx-auto mx-lg-0 mt-2 mt-lg-0" v-if="showEdit && dadosUsuario.usuarioPermissoes.includes('Fiscal | Editar vinculos')">
                  <div class="btn-icone color-theme" v-if="produto.vinculado">
                     <span @click="desvincular"><i class="far fa-unlink"></i><small>Desvincular</small></span>
                  </div>
                  <div class="btn-icone text-red" v-else>
                     <span @click="vincular"><i class="far fa-link"></i><small>Vincular</small></span>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="sub-container" v-if="tabInfo == 'Adicional'">
         <div class="col-12 mb-3">
            <h1 class="font-15 mb-0 mt-1"><i class="far fa-info-circle font-12 color-theme me-2"></i>Informações adicionais</h1>
         </div>
         <div class="col-12">
            <div class="cards-150 cards-sm-200">
               <div class="card shadow-none">
                  <div class="card-body p-12">
                     <h1 class="font-12 mb-1 limitador-1 text-uppercase">Valor total</h1>
                     <p class="font-12 mb-0 text-secondary text-end">
                        <span class="limitador-1">{{ produto.total == null ? 'Sem valor' : 'R$ ' + parseFloat(produto.total).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
                     </p>
                  </div>
               </div>
               <div class="card shadow-none">
                  <div class="card-body p-12">
                     <h1 class="font-12 mb-1 limitador-1 text-uppercase">Valor desconto</h1>
                     <p class="font-12 mb-0 text-secondary text-end">
                        <span class="limitador-1">{{ produto.valorDesconto == null ? 'Sem valor' : 'R$ ' + parseFloat(produto.valorDesconto).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
                     </p>
                  </div>
               </div>
               <div class="card shadow-none">
                  <div class="card-body p-12">
                     <h1 class="font-12 mb-1 limitador-1 text-uppercase">BC ICMS</h1>
                     <p class="font-12 mb-0 text-secondary text-end">
                        <span class="limitador-1">{{ produto.bcIcms == null ? 'Sem BC' : 'R$ ' + parseFloat(produto.bcIcms).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
                     </p>
                  </div>
               </div>
               <div class="card shadow-none">
                  <div class="card-body p-12">
                     <h1 class="font-12 mb-1 limitador-1 text-uppercase">BC ICMS ST</h1>
                     <p class="font-12 mb-0 text-secondary text-end">
                        <span class="limitador-1">{{ produto.bcIcmsSt == null ? 'Sem BC' : 'R$ ' + parseFloat(produto.bcIcmsSt).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
                     </p>
                  </div>
               </div>
               <div class="card shadow-none">
                  <div class="card-body p-12">
                     <h1 class="font-12 mb-1 limitador-1 text-uppercase">BC IPI</h1>
                     <p class="font-12 mb-0 text-secondary text-end">
                        <span class="limitador-1">{{ produto.bcIpi == null ? 'Sem BC' : 'R$ ' + parseFloat(produto.bcIpi).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
                     </p>
                  </div>
               </div>
               <div class="card shadow-none">
                  <div class="card-body p-12">
                     <h1 class="font-12 mb-1 limitador-1 text-uppercase">BC PIS</h1>
                     <p class="font-12 mb-0 text-secondary text-end">
                        <span class="limitador-1">{{ produto.bcPis == null ? 'Sem BC' : 'R$ ' + parseFloat(produto.bcPis).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
                     </p>
                  </div>
               </div>
               <div class="card shadow-none">
                  <div class="card-body p-12">
                     <h1 class="font-12 mb-1 limitador-1 text-uppercase">BC COFINS</h1>
                     <p class="font-12 mb-0 text-secondary text-end">
                        <span class="limitador-1">{{ produto.bcCofins == null ? 'Sem BC' : 'R$ ' + parseFloat(produto.bcCofins).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
                     </p>
                  </div>
               </div>
               <div class="card shadow-none">
                  <div class="card-body p-12">
                     <h1 class="font-12 mb-1 limitador-1 text-uppercase">Valor ICMS</h1>
                     <p class="font-12 mb-0 text-secondary text-end">
                        <span class="limitador-1">{{ produto.valorIcms == null ? 'Sem valor' : 'R$ ' + parseFloat(produto.valorIcms).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
                     </p>
                  </div>
               </div>
               <div class="card shadow-none">
                  <div class="card-body p-12">
                     <h1 class="font-12 mb-1 limitador-1 text-uppercase">Valor ICMS ST</h1>
                     <p class="font-12 mb-0 text-secondary text-end">
                        <span class="limitador-1">{{ produto.valorIcmsSt == null ? 'Sem valor' : 'R$ ' + parseFloat(produto.valorIcmsSt).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
                     </p>
                  </div>
               </div>
               <div class="card shadow-none">
                  <div class="card-body p-12">
                     <h1 class="font-12 mb-1 limitador-1 text-uppercase">Valor IPI</h1>
                     <p class="font-12 mb-0 text-secondary text-end">
                        <span class="limitador-1">{{ produto.valorIpi == null ? 'Sem valor' : 'R$ ' + parseFloat(produto.valorIpi).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
                     </p>
                  </div>
               </div>
               <div class="card shadow-none">
                  <div class="card-body p-12">
                     <h1 class="font-12 mb-1 limitador-1 text-uppercase">Valor PIS</h1>
                     <p class="font-12 mb-0 text-secondary text-end">
                        <span class="limitador-1">{{ produto.valorPis == null ? 'Sem valor' : 'R$ ' + parseFloat(produto.valorPis).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
                     </p>
                  </div>
               </div>
               <div class="card shadow-none">
                  <div class="card-body p-12">
                     <h1 class="font-12 mb-1 limitador-1 text-uppercase">Valor COFINS</h1>
                     <p class="font-12 mb-0 text-secondary text-end">
                        <span class="limitador-1">{{ produto.valorCofins == null ? 'Sem valor' : 'R$ ' + parseFloat(produto.valorCofins).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
                     </p>
                  </div>
               </div>
               <div class="card shadow-none">
                  <div class="card-body p-12">
                     <h1 class="font-12 mb-1 limitador-1 text-uppercase">Valor Frete</h1>
                     <p class="font-12 mb-0 text-secondary text-end">
                        <span class="limitador-1">{{ produto.valorFrete == null ? 'Sem valor' : 'R$ ' + parseFloat(produto.valorFrete).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
                     </p>
                  </div>
               </div>
               <div class="card shadow-none">
                  <div class="card-body p-12">
                     <h1 class="font-12 mb-1 limitador-1 text-uppercase">CST ICMS</h1>
                     <p class="font-12 mb-0 text-secondary text-end"><span class="limitador-1">{{ produto.cstIcms == null ? 'Sem CST' : produto.cstIcms }}</span></p>
                  </div>
               </div>
               <div class="card shadow-none">
                  <div class="card-body p-12">
                     <h1 class="font-12 mb-1 limitador-1 text-uppercase">CST PIS/COFINS</h1>
                     <p class="font-12 mb-0 text-secondary text-end"><span class="limitador-1">{{ produto.cstPisCofins == null ? 'Sem CST' : produto.cstPisCofins }}</span></p>
                  </div>
               </div>
               <div class="card shadow-none">
                  <div class="card-body p-12">
                     <h1 class="font-12 mb-1 limitador-1 text-uppercase">CST IPI</h1>
                     <p class="font-12 mb-0 text-secondary text-end"><span class="limitador-1">{{ produto.cstIPI == null ? 'Sem CST' : produto.cstIPI }}</span></p>
                  </div>
               </div>
               <div class="card shadow-none">
                  <div class="card-body p-12">
                     <h1 class="font-12 mb-1 limitador-1 text-uppercase">CFOP</h1>
                     <p class="font-12 mb-0 text-secondary text-end"><span class="limitador-1">{{ produto.cfop == null ? 'Sem CFOP' : produto.cfop }}</span></p>
                  </div>
               </div>
               <div class="card shadow-none">
                  <div class="card-body p-12">
                     <h1 class="font-12 mb-1 limitador-1 text-uppercase">Origem</h1>
                     <p class="font-12 mb-0 text-secondary text-end"><span class="limitador-1">{{ produto.origem == null ? 'Sem origem' : produto.origem }}</span></p>
                  </div>
               </div>
               <div class="card shadow-none">
                  <div class="card-body p-12">
                     <h1 class="font-12 mb-1 limitador-1 text-uppercase">CEST</h1>
                     <p class="font-12 mb-0 text-secondary text-end"><span class="limitador-1">{{ produto.cestProduto == null ? 'Sem CEST' : produto.cestProduto }}</span></p>
                  </div>
               </div>
               <div class="card shadow-none">
                  <div class="card-body p-12">
                     <h1 class="font-12 mb-1 limitador-1 text-uppercase">NCM</h1>
                     <p class="font-12 mb-0 text-secondary text-end"><span class="limitador-1">{{ produto.ncmProduto == null ? 'Sem NCM' : produto.ncmProduto }}</span></p>
                  </div>
               </div>
               <div class="card shadow-none">
                  <div class="card-body p-12">
                     <h1 class="font-12 mb-1 limitador-1 text-uppercase">Cód. Beneficío</h1>
                     <p class="font-12 mb-0 text-secondary text-end"><span class="limitador-1">{{ produto.codBeneficio == null ? 'Sem código' : codBeneficio.cstPisCofins }}</span></p>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="sub-container d-block" v-else-if="tabInfo == 'Preços'">
         <div class="row">
            <div class="col-12 mb-3">
               <h1 class="font-15 mb-0 mt-1"><i class="far fa-dollar-sign font-12 color-theme me-2"></i>Preços</h1>
            </div>
            
            <preco v-for="(preco, index) in produto.precos" :key="index" :preco="preco" :index="index" :showCusto="false" :showEcommerce="true" 
               :showEdit="showEdit && dadosUsuario.usuarioPermissoes.includes('Fiscal | Editar vinculos')" />
            
            <div class="col-12 my-5 text-center" v-if="produto.precos.length == 0">
               <i class="fal fa-store-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
               <h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum preço encontrado</h4>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import preco from '@/components/produtos/meusProdutos/LojaPreco.vue'

export default {
   name: 'Produto',
   props: ['produto', 'index', 'showEdit', 'entradaEstoque'],
   data : function () {
      return {
         tabInfo: null
      }
   },
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         urlRest: state => state.urlRest
      })
   },
   watch: {
      'produto.qtd': function (newVal) {
         this.$store.dispatch('formatValue', {'valor': newVal, 'digitos': 4}).then((valor) => {
            this.produto.qtd = valor
         })
      },
      'produto.unitario': function (newVal) {
         this.$store.dispatch('formatValue', {'valor': newVal, 'digitos': 4}).then((valor) => {
            this.produto.unitario = valor
         })
      }
   },
   components: {
      preco
   },
   methods: {
      toggleInfo : function (info) {
         if (this.tabInfo != null && this.tabInfo == info) {
            this.tabInfo = null;
         } else {
            this.tabInfo = info;
         }
      },
      vincular : function () {
         this.$emit('vincular', this.produto)
      },
      desvincular : function () {
         Swal.fire({
            icon: 'warning',
            title: 'Desvincular produto?',
            text: 'O produto será desvinculado desse código/fornecedor.',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`

         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)

               this.$axios({
                  method: 'post',
                  url: this.urlRest +'fiscal/desvincularProduto',
                  params: {
                     idItemNota: this.produto.id
                  }
               }).then(() => {
                  this.$emit('atualizar')

                  this.$toast.fire({
                     icon: 'success',
                     title: 'Produto desvinculado!'
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         });
      }
   }
}

</script>